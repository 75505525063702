<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="advertiser")

			b-tabs(content-class="mt-3", pills, card)
				b-tab(title="Main", style="padding: 0")
					.row
						.col-md-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Advertiser Information <span v-if="advertiser.id">({{ advertiser.id }})</span>
									.card-toolbar
								.card-body
									.row
										.col-sm-6
											.form-group
												label Name
												input.form-control(type="text", v-model="advertiser.name")
										.col-sm-6
											.form-group
												label Network
												select.form-control(v-model="advertiser.network_id", style="width: 120px;")
													option(:value="null") - All -
													option(v-for="n in networkOptions", :value="n.v") {{ n.t }}
									.form-group
										b-checkbox(v-model="advertiser.active") Active
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Panel Information
									.card-toolbar
								.card-body
									.row
										.col-sm-12
											.form-group
												label Panel URL
												input.form-control(type="text", v-model="advertiser.panel_url")
									.row
										.col-sm-6
											.form-group
												label Username
												input.form-control(type="text", v-model="advertiser.panel_username")
										.col-sm-6
											.form-group
												label Password
												input.form-control(type="text", v-model="advertiser.panel_password")
						.col-md-6


							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Misc
									.card-toolbar
								.card-body
									.form-group
										label Comments
										textarea.form-control(v-model="advertiser.comments", rows="5")

				b-tab(title="Integrations", style="padding: 0")
					.row
						.col-sm-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Integration
								.card-body
									.form-group
										label Integration Type
										select.form-control(v-model="advertiser.integration_type", @change="selectIntegration()")
											option(:value="null") - Select -
											option(v-for="o in integrationTypeOptions", v-bind:key="o.v", :value="o.v") {{ o.t }}
									.col-sm-6
										.form-group
											label &nbsp;
											div.toggle-wrapper
												b-checkbox(v-model="advertiser.fetch_api") Fetch API
												b-checkbox(v-model="advertiser.api_auto_run") Auto run API offers
												b-checkbox(v-model="advertiser.api_auto_create") Auto create API offers
												b-checkbox(v-model="advertiser.api_auto_tag") Automatically add offers to tags
												b-checkbox(v-model="advertiser.api_sync_targeting") Sync targeting
												b-checkbox(v-model="advertiser.api_sync_caps") Sync caps
												b-checkbox(v-model="advertiser.scan_required") Scan required
												b-checkbox(v-model="advertiser.ignore_caps") Ignore caps (for effective status)
												b-checkbox(v-model="advertiser.convert_on_payout") Convert on payout (Convert when receiving payout in the postback).


									table.table.table-bordered.table-params
										thead
											tr
												th
													//, @click="addIntegrationParam()"
													button.btn.btn-circle.btn-sm.btn-primary(type="button")
														i.la.la-plus
													| &nbsp;
													span Param
												th Value
												//th &nbsp;
										tbody
											tr(v-for="(p,i) in advertiser.integration_params")
												td {{ p.key }}
												td
													input.form-control(type="text", v-model="p.val")
												//td
													button.btn.btn-sm(@click="advertiser.integration_params.splice(i,1)")
														i.la.la-times
									//p
										button.btn.btn-secondary(type="button", @click="testAPI()") Test
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label AutoConnect to Publishers
								.card-body
									v-select(:options="publisherOptions", v-model="advertiser.auto_publishers", :reduce="o => o.v", label="t", multiple)
									//multiselect(:multiple="true", :options="publisherOptions", v-model="advertiser.auto_publishers",
										track-by="v", label="t", deselect-label="", select-label="")

						.col-sm-6
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Tracking Link
								.card-body
									table.table.table-bordered.table-params
										thead
											tr
												th
													button.btn.btn-circle.btn-sm.btn-primary(type="button", @click="addTrackingLinkParam()")
														i.la.la-plus
													| &nbsp;
													span Param
												th Value
												th &nbsp;
										tbody
											tr(v-for="(p, i) in advertiser.tracking_link_params")
												td
													input.form-control(type="text", v-model="p.key")
												td
													input.form-control(type="text", v-model="p.val")
												td
													button.btn.btn-sm(type="button", @click="advertiser.tracking_link_params.splice(i,1)")
														i.la.la-times
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Postbacks
								.card-body
									.form-group
										label Install Postback
										textarea.form-control(rows="5", :value="installPostback")

</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

async function loadRoute(vm, to, from, next){

	let integrations = await Vue.$dwData.general.getIntegrations();

	let advertiser = {};
	if (to.name === 'advertiser-edit') {
		let entityId = +to.params.id;
		try {
			advertiser = await Vue.$dwData.advertiser.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load advertiser');
			return next(false);
		}
	} else {
		advertiser = Vue.$dwData.advertiser.newInstance();
	}
	let networkOptions = await Vue.$dwData.network.getOptions(true);
	// Vue.ovData.advertiser._booleans.forEach(f => {
	// 	advertiser[f] = !!advertiser[f];
	// });
	let publisherOptions = await Vue.$dwData.publisher.getOptions(true);
	return vm => {
		// vm.publisherOptions = publisherOptions;
		vm.integrationTypeOptions = integrations;
		vm.networkOptions = networkOptions;
		vm.publisherOptions = publisherOptions;
		vm.advertiser = advertiser;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'AdvertiserForm',
	computed: {
		integration: function (){
			for (let i = 0; i < this.integrationTypeOptions.length; i++) {
				if (this.integrationTypeOptions[i].v === this.advertiser.integration_type) {
					return this.integrationTypeOptions[i];
				}
			}
			return null;
		},
		installPostback(){
			let url = this.advertiser.panel_url;
			let params = this.advertiser.tracking_link_params;
			let postback = url + params ? ('?' + params.map(p => p.key + '=' + p.val).join('&')) : '';
			return postback;
		}
	},
	data(){
		return {
			busy: true,
			advertiser: null,
			integrationTypeOptions: [],
			networkOptions: [],
			publisherOptions: []
		};
	},
	methods: {

		selectIntegration(){
			console.log(this.integration.v);
			this.advertiser.integration_params = [...this.integration.api_params];
			this.advertiser.tracking_link_params = [...this.integration.tracking_params];
		},
		addTrackingLinkParam(){
			this.advertiser.tracking_link_params.push({
				key: '',
				val: ''
			});
		},
		async testAPI(){
			let data = {
				integration_type: this.advertiser.integration_type,
				integration_params: this.advertiser.integration_params,
			};
			try {
				let resp = await Vue.$api.post('advertiser/testAPI', data);
				console.log(resp);
			} catch (e){
				console.error(e);
			}
		},

		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.advertiser);
			try {
				let resp = await this.$api.post('advertisers/save', data);
				this.busy = false;
				this.$notify.success('Advertiser has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'advertiser-list'});
				} else {
					this.advertiser = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Advertisers', name: 'advertiser-list'},
			{title: this.advertiser && this.advertiser.id ? 'Edit Advertiser' : 'Add Advertiser'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'advertiser-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
